'use client'

import { LuMessageSquare } from 'react-icons/lu'
import { useChatStore } from '../../lib/store/chat'

export function HeaderChat() {
  const { unread } = useChatStore((state) => ({ unread: state.unread }))

  return (
    <div className='flex gap-2 relative'>
      {
        unread.length > 0 &&
      <div className='absolute flex items-center justify-center bg-red p-1 size-3 rounded-full text-[8px]'>
        <p>
          {
            unread.length > 9
              ? <>9+</>
              : unread.length
          }
        </p>
      </div>
      }
      <LuMessageSquare className='size-6' /> Conversas
    </div>
  )
}